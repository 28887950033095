import { OnnEvent, Failure, Success, Result } from "../../../../domain";
import { toCloudFunctionsCompatible } from "../../../../utils/toCloudFunctionsCompatible";
import { onnEventActionTypes } from "../shared";

import { IRetrieveOnnEventsRequest, retrieveOnnEventsRequestSchema } from "./schema";

export class RetrieveOnnEventsRequest implements IRetrieveOnnEventsRequest {
  static readonly validator = retrieveOnnEventsRequestSchema;

  static actionType: typeof onnEventActionTypes.retrieveOnnEvent = "retrieveOnnEvent";
  onnEventId: OnnEvent["id"];

  constructor(init: RetrieveOnnEventsRequest) {
    this.onnEventId = init.onnEventId;
  }

  // クライアント側でリクエストボディを作成する
  public static createRequestBody({ onnEventId }: { onnEventId: OnnEvent["id"] }) {
    return toCloudFunctionsCompatible({
      actionType: this.actionType,
      onnEventId,
    });
  }

  public static validate(data: RetrieveOnnEventsRequest): Result<RetrieveOnnEventsRequest, Error> {
    const onnEventIdResult = RetrieveOnnEventsRequest.validator.safeParse(data);
    if (!onnEventIdResult.success) {
      return new Failure(new Error(onnEventIdResult.error.message));
    }

    return new Success(new RetrieveOnnEventsRequest(data));
  }
}
