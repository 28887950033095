import { NewGraduateToDisplay } from "@onn/common";
import { useCallback } from "react";

import { useModal } from "~/hooks/modal";

export const useSPNewGraduateDetailModal = () => {
  const { handleModal } = useModal();

  const handleOpenSPNewGraduateDetailModal = useCallback(
    (newGraduate: NewGraduateToDisplay) =>
      handleModal({
        name: "spNewGraduateDetailModal",
        args: {
          newGraduate,
        },
      }),
    [handleModal]
  );

  return {
    handleOpenSPNewGraduateDetailModal,
  };
};
