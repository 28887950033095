import { useNewGraduates } from "../employee";

import { useOnnFormTaskAnswers } from "./useOnnFormTaskAnswers";

// 管理画面でカウントの対象になる回答のみを返すhooks
export const useOnnFormTasksAnswersOnlyCountable = ({ onnTaskId }: { onnTaskId?: string }) => {
  const {
    data: _onnFormTaskAnswers,
    isLoading: isLoadingOnnFormTaskAnswers,
    mutate,
  } = useOnnFormTaskAnswers({
    onnTaskId: onnTaskId,
  });

  const { data: newGraduates = [], isLoading: isLoadingNewGraduate } = useNewGraduates({
    newGraduateIds: _onnFormTaskAnswers?.map((v) => v.employeeId),
  });
  const newGraduateMap = new Map(newGraduates.map((v) => [v.id, v]));
  const onnFormTaskAnswers = _onnFormTaskAnswers?.filter((v) => {
    const newGraduate = newGraduateMap.get(v.employeeId);
    return !newGraduate?.isRejectedOrWithdrew();
  });

  return {
    isLoading: isLoadingOnnFormTaskAnswers || isLoadingNewGraduate,
    data: onnFormTaskAnswers || [],
    mutate,
  };
};
