import { zodResolver } from "@hookform/resolvers/zod";
import { Box } from "@material-ui/core";
import { OnnEvent, OnnEventAnswer, CandidateDateWithNumberOfParticipants } from "@onn/common";

import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { answerNormalEventOnBehalfFormSchema } from "./answerNormalEventOnBehalfFormSchema";

import { Typography } from "~/components/uiParts";
import theme from "~/config/theme";
import { useCurrentUser } from "~/hooks/employee";
import { useUpdateOnnEventAnswer } from "~/hooks/onnEvent";
import { mutateOnnEventAnswers } from "~/hooks/onnEvent/answerResult/useOnnEventAnswers";
import { mutateOnnEventAnswersWithEmployee } from "~/hooks/onnEvent/answerResult/useOnnEventAnswersWithEmployee";
import { mutateDeterminedDate } from "~/hooks/onnEvent/determinedDate/useDeterminedDate";

import { mutateCandidateDatesWithNumberOfParticipants } from "~/hooks/onnEvent/useCandidateDatesWithNumberOfParticipants";
import { mutateOnnEvent } from "~/hooks/onnEvent/useOnnEvent";
import { useSnackbar } from "~/hooks/shared";

type InputState = {
  candidateDateId: string;
};

type Args = {
  onnEvent: OnnEvent;
  currentOnnEventAnswer: OnnEventAnswer;
  onSubmit: () => void;
  candidateDatesWithNumberOfParticipants: CandidateDateWithNumberOfParticipants[];
};

export const useAnswerNormalEventOnBehalfForm = ({
  onnEvent,
  currentOnnEventAnswer,
  onSubmit,
  candidateDatesWithNumberOfParticipants,
}: Args) => {
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useCurrentUser();
  const { execUpdateOnnEventAnswer } = useUpdateOnnEventAnswer();
  const { enqueueSnackbar } = useSnackbar();

  const defaultSelectedValue = useMemo(
    () =>
      currentOnnEventAnswer.isAnswered()
        ? (() => {
            if (currentOnnEventAnswer.isUnavailableCandidates()) return undefined;
            const answeredCandidateDateId = currentOnnEventAnswer.getPossibleCandidateDateId();
            return onnEvent.candidateDates.find((date) => date.id === answeredCandidateDateId)?.id;
          })()
        : undefined,
    [currentOnnEventAnswer, onnEvent.candidateDates]
  );

  const form = useForm<InputState>({
    defaultValues: {
      candidateDateId: defaultSelectedValue,
    },
    mode: "onChange",
    resolver: zodResolver(answerNormalEventOnBehalfFormSchema),
  });

  /**
   * NOTE: 管理者が代理回答を行うので候補日は過去日程も含めて表示する
   */
  const candidateDateOptions = useMemo(
    () =>
      onnEvent.candidateDates.map((date) => {
        const candidateDateDetail = (candidateDatesWithNumberOfParticipants || []).find(
          (v) => v.id === date.id
        );
        const labelDate = `${format(date.from, "MM/dd(E) HH:mm", { locale: ja })}〜${format(
          date.until,
          "HH:mm"
        )}`;
        const label = (() => {
          if (onnEvent.hasCapacity()) {
            if (candidateDateDetail && !candidateDateDetail.canParticipate()) {
              return (
                <Box display="flex" gridGap="16px" alignItems="center">
                  <Typography variant="body2">{labelDate}</Typography>
                  <Box
                    display="flex"
                    p="4px 8px"
                    alignItems="center"
                    bgcolor={theme.palette.grey[50]}
                    borderRadius="100px"
                    height="21px"
                  >
                    <Typography variant="overline" color="textSecondary">
                      定員締切
                    </Typography>
                  </Box>
                </Box>
              );
            }
            return (
              <Box display="flex" gridGap="16px" alignItems="center">
                <Typography variant="body2">{labelDate}</Typography>
                <Box
                  display="flex"
                  p="4px 8px"
                  gridGap="2px"
                  alignItems="center"
                  bgcolor={theme.palette.primary.light}
                  borderRadius="100px"
                  height="21px"
                >
                  <Typography variant="overline" color="textSecondary">
                    定員：
                  </Typography>
                  <Typography variant="overline" color="textPrimary">
                    {date.capacity}名
                  </Typography>
                </Box>
              </Box>
            );
          }
          return labelDate;
        })();

        const disabled = candidateDateDetail && !candidateDateDetail.canParticipate();
        return {
          value: date.id,
          label,
          disabled,
        };
      }),
    [candidateDatesWithNumberOfParticipants, onnEvent]
  );

  return {
    ...form,
    candidateDateOptions,
    isLoading,
    handleSubmit: form.handleSubmit(async (inputValue: InputState) => {
      setIsLoading(true);

      await execUpdateOnnEventAnswer({
        onnEventAnswerId: currentOnnEventAnswer.id,
        // NOTE: 単一回答のみ対応しているため候補日程は1つのみ
        answer: { [inputValue.candidateDateId]: "possible" },
        employeeId: currentOnnEventAnswer.employeeId,
        onnEventId: onnEvent.id,
      })
        .then(() => {
          onSubmit();
          enqueueSnackbar(
            currentOnnEventAnswer.isAnswered() ? "回答を編集しました" : "回答を完了しました",
            { variant: "success" }
          );
          mutateDeterminedDate(onnEvent.id);
          mutateOnnEvent(onnEvent.tenantId, onnEvent.id);
          mutateOnnEventAnswers(onnEvent.id);
          mutateOnnEventAnswersWithEmployee(onnEvent.id);
          mutateCandidateDatesWithNumberOfParticipants(currentUser.id, onnEvent.id);
        })
        .catch(() => {
          enqueueSnackbar("エラーが発生しました。Onnサポートチームまでお問い合わせください。", {
            variant: "error",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }),
  };
};
