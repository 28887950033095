import { z } from "zod";

import { onnEventSchema } from "../../../../domain/OnnEvent/OnnEvent/schema";

export const retrieveOnnEventsRequestSchema = z.object({
  onnEventId: onnEventSchema.shape.id,
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRetrieveOnnEventsRequest extends z.infer<typeof retrieveOnnEventsRequestSchema> {}
