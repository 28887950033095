import React, { FC } from "react";

import { ColumnType, generateHeaderNodeMap } from "./utils";

import { CellItem, Typography } from "~/components/uiParts";

type Props = {
  style: React.CSSProperties;
  columnType: ColumnType;
  isShowRightBorder: boolean;
  numberOfAdjusted: number;
  numberOfIsRejectedOrWithdrew: number;
};

export const HeaderCell: FC<Props> = ({
  columnType,
  numberOfAdjusted,
  numberOfIsRejectedOrWithdrew,
  ...props
}) => {
  const headerNode = generateHeaderNodeMap({ numberOfAdjusted, numberOfIsRejectedOrWithdrew })[
    columnType
  ];

  return (
    <CellItem {...props}>
      {typeof headerNode === "string" ? (
        <Typography variant="body2">{headerNode}</Typography>
      ) : (
        headerNode
      )}
    </CellItem>
  );
};
