import { Employee } from "@onn/common";
import React, { useCallback } from "react";

import { NewGraduateSelectionCore } from "../NewGraduateSelectionCore";

type Props = {
  selectedEmployees: Employee[];
  setSelectedEmployees: (employees: Employee[]) => void;
  height?: number;
};

export const NewGraduateSelection = ({
  selectedEmployees,
  setSelectedEmployees,
  height,
}: Props): JSX.Element => {
  const filter = useCallback((employee: Employee) => employee.canInvite(), []);

  return (
    <NewGraduateSelectionCore
      selectedEmployees={selectedEmployees}
      setSelectedEmployees={setSelectedEmployees}
      filter={filter}
      height={height}
      includeRejectedAndWithdrew={false}
    />
  );
};
