import { Menu, MenuItem } from "@material-ui/core";
import { OnnEventAnswer, NewGraduate, OnnEvent } from "@onn/common";
import React, { FC, useState, useCallback, MouseEvent, useMemo } from "react";

import { IconButton, Typography } from "~/components/uiParts";

import { useModal } from "~/hooks/modal";

type Props = {
  onnEvent: OnnEvent;
  onnEventAnswer: OnnEventAnswer;
  newGraduate: NewGraduate;
};

export const AnswerManageMenu: FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { handleModal } = useModal();

  // 画面遷移を防ぐpreventDefaultとstopPropagation,メニューを閉じるsetStateをひとまとめに実行した後に目的のcallbackを実行する
  const handleClickHF = useCallback((e: MouseEvent<HTMLElement>, callback: () => void) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
    callback();
  }, []);

  const menuOptions = useMemo(() => {
    return [
      {
        id: "answer",
        title: props.onnEventAnswer.isAnswered() ? "回答編集" : "代理回答",
        func: (e: MouseEvent<HTMLElement>) =>
          handleClickHF(e, () => {
            handleModal({
              name: "answerNormalEventOnBehalfModal",
              args: props,
            });
          }),
      },
    ];
  }, [handleClickHF, handleModal, props]);

  const handleOpenMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  }, []);

  return (
    <>
      <IconButton icon="menuVert" onClick={handleOpenMenu} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        {menuOptions.map((option) => {
          return (
            <MenuItem key={option.title} onClick={option.func}>
              <Typography variant="body2">{option.title}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
