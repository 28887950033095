import { Box, Fade } from "@material-ui/core";
import { sleep } from "@onn/common";
import React, { FC, MouseEvent, useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { Icon, TextareaAutosize, Typography } from "~/components/uiParts";
import { useDebouncedCallback } from "~/hooks/shared";

// "保存しました" メッセージの表示を制御する
const useIsSaved = () => {
  const [isSaved, setIsSaved] = useState(false);

  const handleOnSave = useCallback(async () => {
    setIsSaved(true);
    await sleep(2000); // Fade の timeout `{ appear: 1000, enter: 500, exit: 500 }` の合計
    setIsSaved(false);
  }, []);

  return { isSaved, handleOnSave };
};

// ====================
// props
// ====================

type Props = {
  newGraduateId: string;
  memo: string;
  onUpdateMemo: (newMemo: string) => Promise<void>;
};

// ====================
// main
// ====================

/**
 * 高さが可変のメモ入力欄 (メモ入力に伴って高さが半無限に拡大される)
 */
export const NewGraduateMemo: FC<Props> = ({ newGraduateId, memo, onUpdateMemo }) => {
  // ====================
  // state
  // ====================

  // textを同期するために、newGraduateIdを監視する。newGraduateIdが変わったら、textを初期化する
  const [storedNewGraduateId, setStoredNewGraduateId] = useState<string>("");
  const [text, setText] = useState(memo);

  useEffect(() => {
    // newGraduateIdが変わったら、textを同期する
    if (newGraduateId !== storedNewGraduateId) {
      setStoredNewGraduateId(newGraduateId);
      setText(memo);
    }
  }, [memo, newGraduateId, storedNewGraduateId]);

  const handleDebounceCallback = useDebouncedCallback((callback) => callback(), 1000);

  const { isSaved, handleOnSave } = useIsSaved();

  // ====================
  // component
  // ====================

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body2" bold color="textPrimary">
        社内メモ
      </Typography>
      <Typography variant="caption" color="textSecondary">
        このメモは候補者には共有されません
      </Typography>

      <Box my="8px" />

      <StyledTextarea
        placeholder="関係者へのメモを入力しましょう"
        value={text}
        fullWidth
        minRows={2}
        maxRows={Infinity}
        // 上位コンポーネントでのクリック時画面遷移を回避
        onClick={(e: MouseEvent<HTMLElement>) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        // 画面の描画だけ先に済ませ、フォーカスが外れたタイミングで更新
        onChange={(e) => {
          setText(e.target.value);
          handleDebounceCallback(async () => {
            await onUpdateMemo(e.target.value);

            await handleOnSave(); // 保存されましたメッセージを表示する
          });
        }}
      />

      <Box mb="4px" />

      <Box height="12px" display="flex" alignItems="center" justifyContent="right">
        <Fade in={isSaved} timeout={{ appear: 1000, enter: 500, exit: 500 }}>
          <Box display="flex" alignItems="center">
            <Icon icon="checkOutlineCircle" color="primary" size="sm" />
            <Typography variant="caption" color="primary">
              保存されました
            </Typography>
          </Box>
        </Fade>
      </Box>
    </Box>
  );
};

// ====================
// style
// ====================

const StyledTextarea = styled(TextareaAutosize)`
  font-size: 12px;
  height: 72px;

  &:focus {
    // borderが太くなることによる微妙なstyleくずれを相殺する
    padding: 14.5px 15px;
  }
`;
