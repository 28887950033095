import { Box } from "@material-ui/core";
import { onnLogoUrl } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { Paper, Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useFileUrl } from "~/hooks/file";
import paperBgImageBottom from "~/images/paper_background_bottom.svg";
import paperBgImageTop from "~/images/paper_background_top.svg";

export const ThanksToApplication: FC = () => {
  const { currentUser } = useCurrentUser();

  return <ThanksToApplicationCore tenantId={currentUser.tenantId} />;
};

// NOTE: ユーザーの登録が済んでいない箇所でも、お祈り画面を表示したい場合があるので、Coreとして分離しててexportしている
export const ThanksToApplicationCore: FC<{ tenantId: string }> = ({ tenantId }) => {
  const { data: logoUrl, isValidating: isValidatingLogoUrl } = useFileUrl(
    `public/uploads/logo/${tenantId}`
  );

  return (
    <StyledBox px="16px">
      {!isValidatingLogoUrl && (
        <Paper>
          <Box py="24px" display="flex" justifyContent="center">
            <StyledImage src={logoUrl || onnLogoUrl} alt="ロゴ" />
          </Box>
          <Typography variant="body1" color="textSecondary" align="center">
            応募いただき、誠にありがとうございました。
            <br />
            今後も引き続き、弊社の求人情報にご興味をお持ちいただければ幸いです。
          </Typography>
        </Paper>
      )}
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  position: absolute;
  left: 0;
  /* FIXME: 一時的に NotFound で背景表示。背景画像はルールを整理して場所を移す */
  background-image: url(${paperBgImageTop}), url(${paperBgImageBottom});
  background-repeat: no-repeat;
  background-position: right top, left bottom;
  background-size: 60%;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.grey[50]};
`;

const StyledImage = styled.img`
  max-height: 200px;
  max-width: 100%;
  ${(props) => props.onClick && `cursor: pointer;`}
`;
