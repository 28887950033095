import styled from "styled-components";

import { TextareaAutosize } from "~/components/uiParts";

export const StyledTextarea = styled(TextareaAutosize)`
  font-size: 14px;
  height: 72px;

  &:focus {
    // borderが太くなることによる微妙なstyleくずれを相殺する
    padding: 14.5px 15px;
  }
`;
