import { Box } from "@material-ui/core";

import { OnnEvent, OnnEventAnswer } from "@onn/common";
import React from "react";

import { ActionFooter } from "../_share/components/ActionFooter";

import { useHooks } from "./hooks/useHooks";

import { AddEmployeeToDeliverTabsPaper } from "~/components/domains/employees";
import { ScheduleDeliverySettingsPaper } from "~/components/domains/employees/ScheduleDeliverySettingsPaper";
import { Typography, Tooltip, Icon } from "~/components/uiParts";

export const ForNormalEventPage = (props: {
  onnEvent: OnnEvent;
  onnEventAnswers: OnnEventAnswer[];
  mutateOnnEvent: (onnEvent: OnnEvent) => Promise<OnnEvent | null | undefined>;
  mutateOnnEventAnswers: (
    onnEventAnswers: OnnEventAnswer[]
  ) => Promise<OnnEventAnswer[] | undefined>;
}): JSX.Element => {
  const {
    isAlreadyDelivered,
    onClickAddNewGraduateToOnnEventButton,
    onClickUploadCSVButton,
    onClickGoBack,
    onClickCancel,
    onClickConfirmSave,
    deadlineDateInput,
    scheduledDateInput,
    canAnswerAfterDeadline,

    newSelectedNewGraduates,
    keptNewGraduateIds,
    deleteFromNewSelectedNewGraduates,
    deleteFromRegisteredNewGraduates,

    onnEvent,
    isDisabledSaveButton,
    fromPage,
    deadlineDate,
    scheduledDate,
    updateDeadlineDate,
    updateScheduledDate,
    updateCanAnswerAfterDeadline,
  } = useHooks(props);

  return (
    <Box display="flex" justifyContent="center">
      <Box width="800px">
        <Box mb="24px" display={"flex"} alignItems={"center"} gridGap={"8px"}>
          <Typography variant="h4" bold>
            配信設定
          </Typography>
          <Tooltip
            title="配信時に候補者ステータスが「辞退」「不合格」になっている対象者や削除された対象者には配信されません。"
            placement="right-start"
          >
            <Icon icon="help" size="sm" color="grey" />
          </Tooltip>
        </Box>
        <Box mb="40px">
          <Typography variant="body1" color="textPrimary">
            「{onnEvent.title}」の配信対象を追加してください
          </Typography>
        </Box>

        <Box mb={"24px"}>
          <ScheduleDeliverySettingsPaper
            fixedScheduledDate={isAlreadyDelivered ? onnEvent.scheduledDate : undefined}
            scheduledDateInput={scheduledDateInput}
            deadlineDateInput={deadlineDateInput}
            canAnswerAfterDeadline={canAnswerAfterDeadline}
            updateCanAnswerAfterDeadline={updateCanAnswerAfterDeadline}
            scheduledDate={scheduledDate}
            deadlineDate={deadlineDate}
            updateScheduledDate={updateScheduledDate}
            updateDeadlineDate={updateDeadlineDate}
          />
        </Box>

        {/* TODO: interviewタイプのイベントで入社者の回答を許可するタイミングで再表示する */}
        {onnEvent.type === "normal" && (
          <Box mb={"40px"}>
            <AddEmployeeToDeliverTabsPaper
              isAlreadyDelivered={isAlreadyDelivered}
              onClickAddNewGraduateToButton={onClickAddNewGraduateToOnnEventButton}
              onClickUploadCSVButton={onClickUploadCSVButton}
              deleteButtonHandlers={{
                fromNewSelectedNewGraduates: deleteFromNewSelectedNewGraduates,
                fromRegisteredNewGraduates: deleteFromRegisteredNewGraduates,
              }}
              newSelectedNewGraduates={newSelectedNewGraduates}
              registeredNewGraduateIds={keptNewGraduateIds}
            />
          </Box>
        )}
        <ActionFooter
          cancelButtonText={fromPage === "create" ? "あとで設定" : "キャンセル"}
          submitButtonText={isAlreadyDelivered ? "保存して配信" : "配信予約"}
          onClickGoBack={onClickGoBack}
          onClickCancel={onClickCancel}
          onClickConfirmSave={onClickConfirmSave}
          isDisabledSaveButton={isDisabledSaveButton}
        />
      </Box>
    </Box>
  );
};
