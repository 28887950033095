import { Box } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

import styled, { css } from "styled-components";

import { Icon, Typography } from "~/components/uiParts";
import { Badge } from "~/components/uiParts/Badge";

type Props = {
  disabledContactRoomIcon: boolean;
  contactRoomId: string | undefined;
  unreadContactMessageCount: number | undefined;
};
export const NewGraduateContactRoom = ({
  disabledContactRoomIcon,
  contactRoomId,
  unreadContactMessageCount,
}: Props) => {
  return (
    <StyledBox height="40px">
      <StyledLink
        to={`/contact_rooms?contactRoomId=${contactRoomId}`}
        disabled={disabledContactRoomIcon}
      >
        <Badge
          badgeContent={
            unreadContactMessageCount && unreadContactMessageCount > 0 ? (
              <Typography bold variant="caption">
                {unreadContactMessageCount}
              </Typography>
            ) : undefined
          }
          color="secondary"
        >
          <Icon
            icon="paperAirplane"
            color={disabledContactRoomIcon ? "lightGrey" : "primary"}
            size="md"
          />
        </Badge>
      </StyledLink>
    </StyledBox>
  );
};
const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledLink = styled(Link)<{ disabled: boolean }>`
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;
