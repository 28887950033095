import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import { isEmpty } from "lodash";
import React, { useState, useCallback, useMemo, FC } from "react";
import styled from "styled-components";

import { SelectEmployeesForm } from "../SelectEmployeesForm";

import { UserIcon, Button, Typography, Modal } from "~/components/uiParts";
import { useAllEmployees } from "~/hooks/employee";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  newGraduate: Employee | null;
  mentor?: Employee;
  followers?: Employee[];
  onCancel: () => void;
  onSubmit: (employeeIds: string[]) => Promise<void>;
};

export const AssignNewGraduateFollowersModal: FC<Props> = ({
  open,
  newGraduate,
  mentor,
  followers,
  onCancel,
  onSubmit,
}) => {
  const { allEmployees } = useAllEmployees();

  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [sending, setSending] = useState(false);

  // 自分自身、担当者、すでにアサインされているフォロワーのemailから成る配列
  const notAllowEmails = useMemo(() => {
    if (!newGraduate || !followers) return [];
    return [newGraduate.email, ...(mentor?.email || []), ...followers.map((sm) => sm.email)];
  }, [newGraduate, mentor, followers]);

  const handleSubmit = useCallback(async () => {
    const employeeIds: string[] = [];
    selectedEmails.map((email) => {
      const employee = allEmployees.find((v) => v.email === email);
      // ここでemployeeがundefinedになることはないが型ガードのために追加している
      if (employee) {
        employeeIds.push(employee.id);
      }
    });

    setSending(true);
    await onSubmit(employeeIds)
      .then(() => {
        onCancel();
      })
      .finally(() => {
        setSending(false);
      });
  }, [allEmployees, onCancel, onSubmit, selectedEmails]);

  return (
    <Modal
      open={open}
      title="フォロワー追加"
      content={
        <Box>
          <Box pr={3} pb={1} pl={3} display="flex" alignItems="center" gridGap="24px">
            <Box textAlign="center" width="342px" display="inline-block">
              <UserIcon
                username={newGraduate?.getName() || ""}
                profileIconImageUrl={newGraduate?.profileIconImageUrl || ""}
                size="medium"
                borderColor="primary"
              />
              <Box pb={2} />
              <Typography variant="body2" align="center">
                {`${newGraduate?.getName() || ""}さんのフォロワーを\n追加してください`}
              </Typography>
            </Box>
            <Box width="342px" display="inline-block">
              <SelectEmployeesForm
                notAllowEmails={notAllowEmails}
                selectedEmails={selectedEmails}
                onSelectEmails={setSelectedEmails}
                isExcludeNewGraduate={true}
              />
            </Box>
          </Box>
          <Typography variant="caption" color="textSecondary" align="center" display="block">
            Onnに登録されていないメンバーを設定したい場合は、
            <StyledPointerTypography
              display="inline"
              variant="caption"
              bold
              color="primary"
              onClick={() => window.open("/settings/admin", "_blank")}
            >
              こちら
            </StyledPointerTypography>
            から招待を行ってください。
          </Typography>
        </Box>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isEmpty(selectedEmails) || sending}
          >
            {!sending ? "追加する" : "追加中"}
          </Button>
        </StyledButtonContainer>
      }
      onCancel={onCancel}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;

const StyledPointerTypography = styled(Typography)`
  cursor: pointer;
`;
