import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import { inflate } from "pako";
import React, { useMemo } from "react";

import { useNavigate } from "react-router-dom";

import { useContactMessagesOrderForm } from "./hooks/useContactMessagesOrderForm";

import { ReceiverForm } from "./parts/ReceiverForm";
import { ScheduleDateFormPaper } from "./parts/ScheduleDateFormPaper";

import { TextFormPaper } from "./parts/TextFormPaper";

import { Icon, Loading, Tooltip, Typography } from "~/components/uiParts";
import { ActionFooter } from "~/components/uiParts/ActionFooter";
import { useCreateContactMessagesOrder } from "~/hooks/contactMessage/useCreateContactMessagesOrder";
import { useEmployees } from "~/hooks/employee";
import { useLocalStorage, usePrompt } from "~/hooks/shared";

export const EditContactMessagesOrder = (): JSX.Element => {
  const defaultReceiverIds = useDefaultSelectedReceiverIds();
  const { data: defaultReceiverEmployees, isLoading } = useEmployees(defaultReceiverIds);

  if (isLoading) {
    return <Loading size="large" fullHeight />;
  }

  return <EditContactMessagesOrderCore defaultReceiverEmployees={defaultReceiverEmployees ?? []} />;
};

const EditContactMessagesOrderCore = ({
  defaultReceiverEmployees,
}: {
  defaultReceiverEmployees: Employee[];
}): JSX.Element => {
  const navigation = useNavigate();
  const { createOrder } = useCreateContactMessagesOrder();

  const {
    control,
    watch,
    handleSubmit,
    formState: { isValid, isDirty, isSubmitSuccessful, isSubmitting, errors },
    trigger,
    selectableTimeMenuItems,
    selectedEmployees,
    selectEmployees,
    removeEmployee,
    changeFiles,
    setText,
    currentMessage,
  } = useContactMessagesOrderForm(createOrder, defaultReceiverEmployees);

  usePrompt("変更内容を破棄しますか？", !isSubmitSuccessful && isDirty);

  const headline = useMemo(
    () => (
      <>
        <Box mb="24px">
          <Box display={"flex"} alignItems={"center"} gridGap={"8px"}>
            <Typography variant="h4" bold>
              新規メッセージ作成
            </Typography>
            <Tooltip
              title="配信時に候補者ステータスが「辞退」「不合格」になっている対象者や削除された対象者には配信されません。"
              placement="right-start"
            >
              <Icon icon="help" size="sm" color="grey" />
            </Tooltip>
          </Box>
        </Box>
        <Box mb="40px">
          <Typography variant="body1" color="textSecondary">
            配信されたメッセージは、配信対象者ごとに個別のメッセージとして配信されます。
          </Typography>
        </Box>
      </>
    ),
    []
  );

  return (
    <>
      <Box mt="40px" pb="80px" display="flex" justifyContent="center">
        <Box maxWidth="800px">
          {headline}

          <ReceiverForm
            selectedEmployees={selectedEmployees}
            selectEmployees={selectEmployees}
            removeEmployee={removeEmployee}
            errorText={errors["receiverEmployees"]?.message}
          />
          <TextFormPaper
            control={control}
            changeFiles={changeFiles}
            files={watch("files")}
            setText={setText}
            currentMessage={currentMessage}
          />
          <ScheduleDateFormPaper
            control={control}
            trigger={trigger}
            canSelectScheduledDate={!watch("scheduledDate.isInstant")}
            selectableTimeMenuItems={selectableTimeMenuItems}
          />
        </Box>
      </Box>

      <ActionFooter
        isSaving={isSubmitting || isSubmitSuccessful}
        submitButtonText={watch("scheduledDate.isInstant") ? "配信" : "配信予約"}
        onClickCancel={() => navigation("/contact_rooms")}
        onClickConfirmSave={handleSubmit}
        isDisabledSaveButton={!isValid}
      />
    </>
  );
};

const useDefaultSelectedReceiverIds = () => {
  const { retrieveValue, removeValue } = useLocalStorage();

  const defaultReceiverIds = useMemo(() => {
    const compressedReceiverIds = retrieveValue<Uint8Array>("compressedReceiverIds");

    // NOTE: ID1個あたり30bytes = 10,000個あたり300Kbになり圧迫するおそれがあるので、取得後に削除する
    removeValue("compressedReceiverIds");

    return compressedReceiverIds
      ? JSON.parse(inflate(compressedReceiverIds, { to: "string" }))
      : [];
  }, [removeValue, retrieveValue]);

  return defaultReceiverIds as string[];
};
