import { RetrieveOnnEventsRequest, RetrieveOnnEventsResponse } from "@onn/common";
import useSWR, { mutate } from "swr";

import { functionOperator } from "~/infrastructure/api/functionOperator";

const getKey = (tenantId?: string, onnEventId?: string) => {
  if (!tenantId || !onnEventId) return null;
  return `retrieveOnnEvent-${onnEventId}`;
};

export const useOnnEvent = ({
  tenantId,
  onnEventId,
}: {
  tenantId?: string;
  onnEventId?: string;
}) => {
  return useSWR(getKey(tenantId, onnEventId), async () => {
    if (onnEventId === undefined) return;
    const response = await functionOperator.httpsCallFor2ndGen<
      ReturnType<typeof RetrieveOnnEventsRequest.createRequestBody>,
      typeof RetrieveOnnEventsResponse.ResponseBody
    >("onnevent", RetrieveOnnEventsRequest.createRequestBody({ onnEventId }));
    return RetrieveOnnEventsResponse.convertUnixTimeToDate(response.data);
  });
};

export const mutateOnnEvent = (tenantId: string, onnEventId: string) => {
  mutate(getKey(tenantId, onnEventId));
};
