import { RadioQuestion } from "@onn/common";
import React, { FC, useCallback } from "react";

import styled from "styled-components";

import { Action } from "../../reducer";

import { AnswerBaseBox } from "./AnswerBaseBox";

import { Typography, RadioButton, FormControlLabel } from "~/components/uiParts";

export const RadioQuestionForm: FC<{
  question: RadioQuestion;
  selectedOptionId: string;
  dispatch: React.Dispatch<Action>;
}> = ({ question, selectedOptionId, dispatch }) => {
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const optionId = e.target.value;
      dispatch({
        type: "CHANGE_RADIO_ANSWER",
        payload: {
          questionId: question.id,
          selectedOptionId: optionId,
        },
      });
    },
    [dispatch, question.id]
  );

  return (
    <AnswerBaseBox questionTitle={question.title} isRequired={question.isRequired}>
      {question.options.map((option) => {
        const checked = option.id === selectedOptionId;
        return (
          <StyledFormControlLabel
            key={option.id}
            value={option.id}
            control={
              <StyledRadioButton
                color="primary"
                defaultColor={checked ? "primary" : "default"}
                checked={checked}
                onChange={onChange}
              />
            }
            label={<Typography variant="body2">{option.text}</Typography>}
          />
        );
      })}
    </AnswerBaseBox>
  );
};

const StyledRadioButton = styled(RadioButton)`
  padding-left: 0;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    height: 24px;
    margin-left: 0px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;
