import { Box } from "@material-ui/core";
import { OnnFormTask } from "@onn/common";
import React, { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

import styled from "styled-components";

import { ContainerPaper } from "../_share/ContainerPaper";

import { TitleAndDeadlineDate } from "../_share/TitleAndDeadlineDate";

import { Button, Typography, Loading, Paper } from "~/components/uiParts";

import theme from "~/config/theme";
import { useOnnTask } from "~/hooks/onnTask";
import { NotFound } from "~/pages/NotFound";
import { mixin } from "~/util";

export const OnnTasksAnswerThanksPage: FC = () => {
  const { id: onnTaskId } = useParams<"id">();

  const { data: onnTask, isLoading: isLoadingOnnTask } = useOnnTask({
    onnTaskId,
  });

  if (isLoadingOnnTask) return <Loading size="large" fullHeight />;
  if (!onnTask) {
    return <NotFound />;
  }

  if (onnTask.type === "FORM_TASK") {
    return <OnnTasksAnswerThanksPageCore onnFormTask={onnTask} />;
  } else {
    return <>TODOタスク: 未実装</>;
  }
};

const OnnTasksAnswerThanksPageCore: FC<{
  onnFormTask: OnnFormTask;
}> = ({ onnFormTask }) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/portal/onn_tasks/${onnFormTask.id}/answer?isFirst=false`);
  };
  return (
    <ContainerPaper style={{ background: theme.palette.grey[50] }}>
      <TitleAndDeadlineDate title={onnFormTask.title} deadlineDate={null} />
      <StyledPaper>
        <Typography variant="h2" bold color="primary" align="center">
          THANK YOU!
        </Typography>
        <Typography variant="body1" align="center">
          回答が完了しました。
        </Typography>
        {onnFormTask.canEditAnswer() && (
          <Box height="48px">
            <Button
              fullWidth
              variant="outlined"
              color="default"
              borderRadius="circle"
              onClick={onClick}
              fullHeight
            >
              回答を編集
            </Button>
          </Box>
        )}
      </StyledPaper>
      <Box textAlign="center" mt={3}>
        <Typography variant="caption" color="textSecondary">
          ブラウザを閉じて終了してください
        </Typography>
      </Box>
    </ContainerPaper>
  );
};

const StyledPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 40px;
  margin-bottom: 24px;
  padding: 80px;
  ${mixin.portalSp`
    margin-top: 24px;
    padding: 40px;
  `}
`;
