import { Box, Grid } from "@material-ui/core";
import { EmployeeTag } from "@onn/common";
import React, { useCallback } from "react";

import { TagCondition } from "../../../types/condition";

import { SelectTargetButton } from "../../parts/buttons/SelectTargetButton";

import {
  Choice,
  SelectMultipleConditionDropdown,
} from "../../parts/dropdown-menus/SelectMultipleConditionDropdown";

import { CommonProps } from "./type";

import { Loading } from "~/components/uiParts";
import { useEmployeeTags } from "~/hooks/employeeTag";

const TARGET = "tag";

type Props = CommonProps<TagCondition>;
export const TagConditionSelector = ({
  index,
  condition,
  onChangeCondition,
  onChangeTarget,
}: Props): JSX.Element => {
  const { data: employeeTagsData, isLoading } = useEmployeeTags();

  const { onChangeTagIds } = useOnChangeConditions({
    index,
    condition,
    onChangeCondition,
  });
  const { tagChoices } = useChoices({
    allTags: employeeTagsData?.employeeTags,
  });

  if (isLoading) {
    return <Loading size="small" />;
  }

  return (
    <Box width={"100%"}>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <SelectTargetButton
            target={TARGET}
            onChange={(target) => onChangeTarget(index, target)}
          />
        </Grid>
        <Grid item xs={9}>
          <SelectMultipleConditionDropdown
            placeHolder="タグを選択"
            key={"tags"}
            selectedChoices={tagChoices.filter((c) => condition.tagIds?.includes(c.value))}
            choices={tagChoices}
            withSearch
            searchPlaceholder="タグ名で検索"
            onChange={onChangeTagIds}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const useOnChangeConditions = ({
  index,
  condition,
  onChangeCondition,
}: Pick<Props, "index" | "onChangeCondition" | "condition">) => {
  const onChangeTagIds = useCallback(
    (newChoices: Choice<string>[]) => {
      onChangeCondition(index, {
        ...condition,
        tagIds: newChoices.map((c) => c.value),
      });
    },
    [condition, index, onChangeCondition]
  );

  return {
    onChangeTagIds,
  };
};

const useChoices = ({ allTags }: { allTags: EmployeeTag[] | undefined }) => {
  if (!allTags) {
    return {
      tagChoices: [],
    };
  }

  return {
    tagChoices: allTags.map((e) => ({ label: e.name, value: e.id })),
  };
};
