import { Box, Menu, MenuItem } from "@material-ui/core";
import React, { useState } from "react";

import { Button, Icon, Typography } from "~/components/uiParts";

export interface Choice<T extends string> {
  label: string;
  value: T;
}

type Props<T extends string> = {
  key: string;
  placeHolder?: string;
  selectedChoice: Choice<T> | undefined;
  choices: Choice<T>[];
  onChange(newChoice: Choice<T>): void;
};
export const SelectSingleConditionDropdown = <T extends string = string>({
  key,
  selectedChoice,
  placeHolder,
  choices,
  onChange,
}: Props<T>): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <Box width={"100%"}>
      <Button
        color="default"
        variant="outlined"
        borderRadius="regular"
        fullWidth
        onClick={(e) => setAnchorEl(e.currentTarget)}
        endIcon={<Icon icon="dropdownArrow" size="sm" color="grey" />}
      >
        <Box width="100%" display="flex" flex={1}>
          <Typography variant="body2" noWrap>
            {selectedChoice?.label ?? placeHolder ?? "選択してください"}
          </Typography>
        </Box>
      </Button>
      <Menu
        key={key}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClick={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {choices.map((choice) => (
          <Box key={choice.value}>
            <MenuItem key={choice.value} onClick={() => onChange(choice)}>
              <Typography variant="body2">{choice.label}</Typography>
            </MenuItem>
          </Box>
        ))}
      </Menu>
    </Box>
  );
};
