import { AllContactRoom } from "@onn/common";
import { useCallback } from "react";

/**
 * 選考ステータスフィルターに関わるhooks
 */
export const useFilterByRecruitmentStatusIds = () => {
  const filterContactRoomsByRecruitmentStatusIds = useCallback(
    (contactRooms: AllContactRoom[], selectedRecruitmentStatusIds: string[]) => {
      if (selectedRecruitmentStatusIds.length === 0) return contactRooms;
      return contactRooms.filter((contactRoom) =>
        selectedRecruitmentStatusIds.includes(contactRoom.employee?.recruitmentStatusId || "")
      );
    },
    []
  );

  return {
    filterContactRoomsByRecruitmentStatusIds,
  };
};
