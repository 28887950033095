import { Box } from "@material-ui/core";
import { NewGraduateToDisplay } from "@onn/common";
import React, { FC } from "react";

import { EmployeeInformationTable } from "./EmployeeInformationTable";

import { Loading, Paper, Typography } from "~/components/uiParts";
import { useEmployeeInformation } from "~/hooks/employeeInformation";

type Props = {
  newGraduate: NewGraduateToDisplay;
};

export const InformationTab: FC<Props> = ({ newGraduate }) => {
  const { data: employeeInformation, isLoading } = useEmployeeInformation(newGraduate.id);

  return (
    <Box pt={5} pb={5}>
      <Paper style={{ borderRadius: 10 }}>
        <Typography variant="body2" bold>
          基本情報
        </Typography>
        <Box mt={2}>
          {isLoading ? (
            <Loading size="large" />
          ) : (
            <EmployeeInformationTable
              newGraduate={newGraduate}
              employeeInformation={employeeInformation}
            />
          )}
        </Box>
      </Paper>
    </Box>
  );
};
