import { Employee } from "@onn/common";
import React, { FC, useEffect, useMemo, useState } from "react";

import { EmployeeList, EmployeeSearchForm } from "./parts";

import { useAllEmployees } from "~/hooks/employee";
import { useTenant } from "~/hooks/tenant";

type Props = {
  notAllowEmails: string[];
  selectedEmail?: string;
  onSelectEmail: (email: string) => void;
};

export const SelectSingleEmployeesForm: FC<Props> = ({
  notAllowEmails,
  selectedEmail,
  onSelectEmail,
}) => {
  const { tenant } = useTenant();
  const { allEmployees } = useAllEmployees();

  const employeesExceptNewGraduates = useMemo(
    () => allEmployees.filter((v) => !v.isNewGraduate),
    [allEmployees]
  );

  const employeesForSearch = useMemo(() => {
    return (tenant.isActiveNewGraduate ? employeesExceptNewGraduates : allEmployees) ?? [];
  }, [employeesExceptNewGraduates, allEmployees, tenant.isActiveNewGraduate]);

  /**
   * クライアント検索処理で再構成される表示用の配列
   * 新卒向けテナントの場合はMEMBER権限のEmployee(入社者を含まない)、中途向けテナントの場合は全てのEmployee(入社者を含む)を対象とする
   */
  const [displayEmployees, setDisplayEmployees] = useState<Employee[]>(
    (tenant.isActiveNewGraduate ? employeesExceptNewGraduates : allEmployees) ?? []
  );

  useEffect(() => {
    const employees = tenant.isActiveNewGraduate ? employeesExceptNewGraduates : allEmployees;
    setDisplayEmployees(employees ?? []);
  }, [employeesExceptNewGraduates, allEmployees, tenant.isActiveNewGraduate]);

  return (
    <>
      <EmployeeSearchForm
        showPlaceholder
        employees={employeesForSearch}
        onSearch={(results) => setDisplayEmployees(results)}
      />
      <EmployeeList
        employees={displayEmployees.filter((v) => !notAllowEmails.includes(v.email))}
        selectedEmployee={employeesForSearch.find((v) => selectedEmail === v.email)}
        onSelect={(employee) => onSelectEmail(employee.email)}
        isMultiple={false}
      />
    </>
  );
};
