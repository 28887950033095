import { Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import {
  StatusForDisplayEventTable,
  invalidStatus,
} from "../../utils/getStatusForDisplayEventTable";

import { Icon } from "~/components/uiParts";
import theme from "~/config/theme";
import { OnnEventAnswerWithEmployee } from "~/hooks/onnEvent";

const statusForLightGreyIcon = ["not_notifiable", "withdrew", "rejected"];

/**
 * 参加できる日程がない場合に表示するセル
 * // FIXME: 単一回答ではない場合を想定しなければならない
 */
export const AnswerIconForUnAvailable = ({
  onnEventAnswerWithEmployee,
  statusForDisplayEventTable,
}: {
  onnEventAnswerWithEmployee: OnnEventAnswerWithEmployee;
  statusForDisplayEventTable: StatusForDisplayEventTable;
}) => {
  if (!onnEventAnswerWithEmployee.isAnswered()) {
    const bgColor = invalidStatus.includes(statusForDisplayEventTable)
      ? theme.palette.grey[50]
      : "white";

    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgcolor={bgColor}
      >
        <Icon color="lightGrey" size="xl" icon="horizontalBar" />
      </Box>
    );
  }

  return onnEventAnswerWithEmployee.isUnavailableCandidates() ? (
    <UnAvailableAnswerIconWrapper width={"100%"} bgcolor={theme.palette.primary.light}>
      <Box width={"40px"}>
        <StyledIcon
          size="lg"
          icon="circle"
          color={statusForLightGreyIcon.includes(statusForDisplayEventTable) ? "grey300" : "blue"}
        />
      </Box>
    </UnAvailableAnswerIconWrapper>
  ) : (
    <UnAvailableAnswerIconWrapper width={"100%"} />
  );
};

const UnAvailableAnswerIconWrapper = styled(Box)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.palette.grey[50]};
`;

const StyledIcon = styled(Icon)`
  display: flex;
  text-align: center;
`;
