import { Box } from "@material-ui/core";
import { OnnEvent, NewGraduate, OnnEventAnswer } from "@onn/common";

import React, { FC } from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";

import { useAttendNormalEventOnBehalfForm } from "./hooks/useAnswerNormalEventOnBehalfForm";

import { CandidateDateOptionLabel } from "./parts/CandidateDateOptionLabel";

import {
  Button,
  Typography,
  ScrollableBodyModal,
  FormControlLabel,
  RadioButton,
} from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useCandidateDatesWithNumberOfParticipants } from "~/hooks/onnEvent";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  onnEvent: OnnEvent;
  onnEventAnswer: OnnEventAnswer;
  newGraduate: NewGraduate;
};

export const AttendNormalEventOnBehalfModal: FC<Props> = ({
  open,
  onCancel,
  onnEvent,
  onnEventAnswer,
  newGraduate,
}) => {
  const { currentUser } = useCurrentUser();
  const { data: candidateDatesWithNumberOfParticipants } =
    useCandidateDatesWithNumberOfParticipants({
      onnEventId: onnEvent.id,
      currentEmployeeId: currentUser.id,
    });
  const { candidateDateOptions, control, handleSubmit, isLoading, formState } =
    useAttendNormalEventOnBehalfForm({
      onnEvent,
      currentOnnEventAnswer: onnEventAnswer,
      onSubmit: onCancel,
      candidateDatesWithNumberOfParticipants: candidateDatesWithNumberOfParticipants || [],
    });

  const Content = (
    <>
      <Typography variant="body1" align="center">
        {newGraduate.getName()}さんの参加日程を確認して、候補者ステータス変更を保存してください。
      </Typography>
      <form
        onSubmit={handleSubmit}
        style={{
          overflowY: "scroll",
          marginTop: "24px",
          display: "flex",
          flexDirection: "column",
          gridRowGap: "24px",
        }}
      >
        <Controller
          name="candidateDateId"
          control={control}
          render={({ field }) => (
            <>
              {candidateDateOptions.map((option) => {
                const checked = option.value === field.value;
                return (
                  <StyledFormControlLabel
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                    control={
                      <StyledRadioButton
                        color="primary"
                        defaultColor={checked ? "primary" : "default"}
                        checked={checked}
                        onChange={field.onChange}
                      />
                    }
                    label={
                      option.candidateDate && (
                        <CandidateDateOptionLabel
                          onnEvent={onnEvent}
                          candidateDate={option.candidateDate}
                        />
                      )
                    }
                  />
                );
              })}
            </>
          )}
        />
      </form>
    </>
  );

  return (
    <ScrollableBodyModal
      open={open}
      title="ステータス変更｜参加日程確認"
      content={Content}
      onCancel={onCancel}
      footer={
        <StyledButtonContainer width="100%">
          <Button
            fullWidth
            borderRadius="circle"
            variant="outlined"
            color="default"
            onClick={onCancel}
          >
            戻る
          </Button>
          <Button
            type="submit"
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            isLoading={isLoading}
            disabled={!formState.isValid}
          >
            保存
          </Button>
        </StyledButtonContainer>
      }
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    height: 24px;
    margin-left: 0px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;

const StyledRadioButton = styled(RadioButton)`
  padding-left: 0;
`;
