import { EmployeeActiveLog, EMPLOYEE_ACTION_TYPE } from "@onn/common";
import { useEffect, useState, Dispatch, SetStateAction, useCallback } from "react";

import { useCurrentUser } from "../employee/useCurrentUser";

import { EmployeeActiveLogUseCase } from "~/service/usecases/employeeActiveLogUseCase";

const employeeActiveLogUseCase = new EmployeeActiveLogUseCase();

/**
 * アクティブログを取得する
 * @param employeeId 対象のemployeeId
 * @param type 取得するログのタイプ
 * @returns isLoading データを取得中かどうか
 * @returns data useActiveLogsの配列
 * @returns setData useActiveLogsの更新関数
 * @returns fetchData データの再取得
 */
export const useEmployeeActiveLogs = (
  employeeId: string,
  type: (typeof EMPLOYEE_ACTION_TYPE)[keyof typeof EMPLOYEE_ACTION_TYPE]
): {
  isLoading: boolean;
  data: EmployeeActiveLog[];
  setData: Dispatch<SetStateAction<EmployeeActiveLog[]>>;
  fetchData: () => void;
} => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<EmployeeActiveLog[]>([]);

  const { currentUser } = useCurrentUser();

  const fetchData = useCallback(() => {
    setIsLoading(true);
    employeeActiveLogUseCase
      .findByEmployeeIdAndType(employeeId, currentUser.tenantId, type)
      .then((res) => setData(res))
      .finally(() => setIsLoading(false));
  }, [currentUser.tenantId, employeeId, type]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    isLoading,
    data,
    setData,
    fetchData,
  };
};
