import { OnnEvent } from "../../../../domain";
import {
  toCloudFunctionsCompatible,
  ToCloudFunctionsCompatible,
} from "../../../../utils/toCloudFunctionsCompatible";
import { convertUnixTimeToDateOnnEvent } from "../../_shared/convertUnixTimeToDate";

export class RetrieveOnnEventsResponse {
  static ResponseBody: { onnEvent: ToCloudFunctionsCompatible<OnnEvent> | null };
  public static createResponseBody({ onnEvent }: { onnEvent: OnnEvent | null }) {
    return { onnEvent: onnEvent ? toCloudFunctionsCompatible(onnEvent) : null };
  }

  public static convertUnixTimeToDate(response: typeof this.ResponseBody) {
    const onnEvent = response.onnEvent;
    if (!onnEvent) {
      return null;
    }

    return convertUnixTimeToDateOnnEvent(onnEvent);
  }
}
