import { APISchema, EMPLOYEE_ACTION_TYPE, EmployeeActiveLog } from "@onn/common";
import useSWR from "swr";

import { useCurrentUser } from "../employee/useCurrentUser";

import { apiClient } from "~/libs";

const generateEmployeeActiveLogsByTypeKey = (
  tenantId: string,
  onnEventId: string,
  type: (typeof EMPLOYEE_ACTION_TYPE)[keyof typeof EMPLOYEE_ACTION_TYPE]
) => {
  return { path: "/get_employee_active_logs", tenantId, onnEventId, type } as const;
};

type EndPoint = APISchema["/get_employee_active_logs"]["GET"];

export const useEmployeeActiveLogsByType = ({
  onnEventId,
  type,
}: {
  onnEventId: string;
  type: (typeof EMPLOYEE_ACTION_TYPE)[keyof typeof EMPLOYEE_ACTION_TYPE];
}) => {
  const { currentUser } = useCurrentUser();

  return useSWR(
    generateEmployeeActiveLogsByTypeKey(currentUser.tenantId, onnEventId, type),
    async ({ path, onnEventId, type }) => {
      const query: EndPoint["query"] = {
        "target-id": onnEventId,
        type,
      };
      const response = await apiClient.get(path, query);
      return response.data.employeeActiveLogs.map((d) => {
        return new EmployeeActiveLog(d);
      });
    }
  );
};
