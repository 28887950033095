import { zodResolver } from "@hookform/resolvers/zod";
import { Employee } from "@onn/common";
import { useCallback } from "react";
import { useForm } from "react-hook-form";

import { useNavigate } from "react-router-dom";

import {
  ContactMessagesOrderForm,
  ContactMessagesOrderFormOutput,
  contactMessagesOrderFormSchema,
} from "./contactMessagesOrderFormSchema";

import { getTimeMenuItems } from "~/util/getTimeMenuItems";

export const useContactMessagesOrderForm = (
  onSubmit: (_params: {
    text: string;
    files: File[];
    receiverEmployeeIds: string[];
    scheduledDate?: Date;
  }) => Promise<void>,
  defaultReceiverEmployees?: Employee[]
) => {
  const navigation = useNavigate();
  const form = useForm<ContactMessagesOrderForm>({
    defaultValues: {
      text: "",
      files: [],
      receiverEmployees: defaultReceiverEmployees ?? [],
      scheduledDate: {
        isInstant: false,
        date: new Date(),
        time: (
          getTimeMenuItems("15_MINUTE")[0] as Array<{
            value: string;
            name: string;
          }>[number]
        ).value,
      },
    },
    mode: "onChange",
    resolver: zodResolver(contactMessagesOrderFormSchema),
  });

  const selectEmployees = useCallback(
    (newEmployees: Employee[]) =>
      form.setValue(
        "receiverEmployees",
        [...form.watch("receiverEmployees"), ...newEmployees],
        options
      ),
    [form]
  );
  const removeEmployee = useCallback(
    (employeeId: string) =>
      form.setValue(
        "receiverEmployees",
        form.watch("receiverEmployees").filter((e) => e.id !== employeeId),
        options
      ),
    [form]
  );

  const changeFiles = useCallback(
    (newFiles: (File | Pick<File, "name">)[]): void => {
      form.setValue(
        "files",
        newFiles.filter((v): v is File => {
          return v instanceof File;
        }),
        options
      );
    },
    [form]
  );

  const setText = useCallback(
    (templateMessage: string) => {
      form.setValue("text", templateMessage, options);
    },
    [form]
  );

  const currentMessage = form.watch("text");

  return {
    ...form,
    selectableTimeMenuItems: getTimeMenuItems("15_MINUTE"),
    selectedEmployees: form.watch("receiverEmployees"),
    selectEmployees,
    removeEmployee,
    changeFiles,
    setText,
    currentMessage,
    handleSubmit: form.handleSubmit((values: ContactMessagesOrderForm) => {
      // values は schema の outputの型になっているはずだが、型定義上はそうなっていない。
      // そのため、型を変換する
      const transformedValues = values as ContactMessagesOrderFormOutput;

      onSubmit({
        ...transformedValues,
        receiverEmployeeIds: transformedValues.receiverEmployees.map((e) => e.id),
        scheduledDate: values.scheduledDate.isInstant
          ? undefined
          : transformedValues.scheduledDate.scheduledDate,
      }).then(() => navigation("/contact_rooms"));
    }),
  };
};

const options = {
  shouldValidate: true,
  shouldDirty: true,
};
